import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import s from './HeaderBar.css';
import { accessoriesSearchApiV2, globalSeacrhApi } from '../../_services/api/accessories/accessoriesSearchApi';
import { accessoriesSearchGtmEvent, viewSearchResults, searchClicked} from '../../gtm/gtmServices';
import { setActiveServiceType } from '../../actions/serviceType';
import { switchServiceType } from '../../_services/urlServices';
import autoUtmHistory from '../../_services/autoUtmHistory';
import history from '../../history';
import generate from '../../serverRoutes/urlGenerators/newUrl';

const SearchBoxMobile = (props) => {
  useStyles(s);
  let refInput = null;
  const {
    accessories, city, accessoriesCarDetail, user, searchTag, close,
    servicesDetails, servicesTypesList,
  } = props;

  const noResult = (
    <div className={s.searchItem}>
      <div className={s.searchItemText}>No Result</div>
    </div>
  );

  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [onSearch, setOnSearch] = useState(new Subject());
  const [searching, setSearching] = useState(false);

  const searchInputHanlder = (e) => {
    setSearchText(e.target.value);
    setSearchResults([]);
    onSearch.next(e.target.value);
  };

  const recentSearches = accessories ? (
    <div className={s.searchItem}>
      <img
        style={{ marginRight: '1rem', width: '20px', height: '20px' }}
        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/history-24px.svg"
        alt="recent"
      />
      <div className={s.searchItemText}>Mobile Charger</div>
    </div>
  ) : (
    <div className={s.searchItem}>
      <img
        style={{ marginRight: '1rem', width: '20px', height: '20px' }}
        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/history-24px.svg"
        alt="recent"
      />
      <div className={s.searchItemText}>Service</div>
    </div>
  );

  useEffect(() => {
    const subscription = onSearch
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchString) => {
        if (accessories && searchString && searchString.length > 0) {
          setSearching(true);
          accessoriesSearchApiV2(searchString).subscribe(
            (res) => {
              if (res.status) {
                setSearchResults(res.data);
                setSearching(false);
                setSearchTerm(searchString);
              }
            },
            (err) => {
              console.error(err);
              setSearching(false);
            },
          );
        } else if (city && searchString && searchString.length > 0) {
          setSearching(true);
          const carTypeId = props.cardetails && props.cardetails?.fuel
            ? props.cardetails?.fuel.car_type_id : null;
          const cityId = props.city?.id;
          globalSeacrhApi(searchString, carTypeId, cityId, props.user.id).subscribe((res) => {
            let results = [];
            fireSearchEvent(searchString);
            results = [...results, ...res.data.retail];
            if (Array.isArray(res.data.tb)) {
              results = [...results, ...res.data.tb];
            }
            if (res.status) setSearchResults(results);
            setSearching(false);
          },
          (err) => {
            console.error(err);
            setSearching(false);
          });
        }
      });
    return () => subscription.unsubscribe();
  }, [onSearch]);

  const fireSearchEvent = (searchTerm) => {
    if (searchTerm.length) {
      viewSearchResults(
        props?.user?.id, props?.cardetails?.model?.id, searchTerm,
      );
    }
  };

  useEffect(() => {
    if (searchTerm.length) {
      const term = searchTerm;
      const car_brand = accessoriesCarDetail.brand ? accessoriesCarDetail.brand.name : null;
      const user_id = user && user.id ? user.id : null;
      const pagepath = window ? window.location.href : null;
      const city = props.city ? props.city?.name : null;
      accessoriesSearchGtmEvent({
        term, car_brand, user_id, pagepath, city,
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTag && searchTag !== '') {
      setSearchText(searchTag);
      setSearchResults([]);
      onSearch.next(searchTag);
      if (refInput) {
        refInput.value = searchTag;
        refInput.focus();
      }
    }
  }, [searchTag]);

  const getAccessoriesSearch = () => {
    const searchArray = [];
    if (searchResults.categories && searchResults.categories.length) {
      searchArray.push(<div className={s.resultHeader}>Category</div>);
      searchArray.push(searchResults.categories.map((result) => (
        <div
          className={s.searchItem}
          onClick={(e) => {
            e.preventDefault();
            if (refInput) {
              refInput.value = result.name;
              refInput.blur();
            }
            autoUtmHistory(history).push(`/accessories/${result.slug}`);
            close();
          }}
        >
          <img
            style={{ marginRight: '1rem', width: '11.8px', height: '12.6px' }}
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/Group%20106.svg"
            alt="recent"
          />
          <div className={s.searchItemText}>{result.name}</div>
        </div>
      )));
    }
    if (searchResults.products && searchResults.products.length) {
      searchArray.push(<div className={s.resultHeader}>Product</div>);
      searchArray.push(searchResults.products.map((result) => (
        <div
          className={s.searchItem}
          onClick={(e) => {
            e.preventDefault();
            if (refInput) {
              refInput.value = result.name;
              refInput.blur();
            }
            autoUtmHistory(history).push(`/accessories/${result.slug}-${result.product_id}?variant=${result.sku_code}`);
            close();
          }}
        >
          <img
            style={{ marginRight: '1rem', width: '11.8px', height: '12.6px' }}
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/Group%20106.svg"
            alt="recent"
          />
          <div className={s.searchItemText}>{result.title}</div>
        </div>
      )));
    }
    return searchArray;
  };

  return (
    <>
      <div className={s.searchPanelMain}>
        <div className={s.searchInputType}>
          <img
            onClick={close}
            alt="close"
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/ic_arrow_back_24px.svg"
          />
          <input
            type="text"
            ref={(e) => { refInput = e; }}
            onChange={searchInputHanlder}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && accessories) {
                const tag = encodeURIComponent(e.target.value.trim());
                if (tag) {
                  autoUtmHistory(history).push(`/accessories/tags/${tag}`);
                  close();
                }
              }
            }}
            className={s.searchTextInput}
            placeholder={
              accessories ? 'Seach Accessories' : 'Example: Periodic Services'
            }
          />
          <img
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/Group%20106.svg"
            alt="search"
            onClick={() => {
              const tag = encodeURIComponent(searchText.trim());
              if (tag && accessories) {
                autoUtmHistory(history).push(`/accessories/tags/${tag}`);
                close();
              }
            }}
          />
        </div>
        <div className={s.searchContainer}>
          <div className={s.searchResults}>
            {!(searchResults.length > 0
            || (searchResults.categories && searchResults.categories.length)
            || (searchResults.products && searchResults.products.length))
            && (!searching && searchText.length > 0 ? noResult : null)}
            {accessories ? getAccessoriesSearch()
              : searchResults.map((result) => (
                <>
                  <div
                    className={s.searchItem}
                    onClick={(e) => {
                      e.preventDefault();
                      const data = { noDataExists: true, service_slug: result.service_slug || result.slug };
                      searchClicked(props?.user?.id, props?.cardetails?.model?.id, searchText, result.name);
                      if(result.id == 7194) {
                        autoUtmHistory(history).push("/miles-membership")
                      }else if(result.id == 8215){
                        autoUtmHistory(history).push("/top-assist")
                      }else {
                        autoUtmHistory(history).push((generate({
                          city: props.city,
                          subCity: props.selectedRegion,
                          service: data,
                          brand: props.cardetails?.brand,
                          model: props.cardetails?.model,
                          fuel: props.cardetails?.fuel,
                        })), data);
                      } 
                      if (refInput) {
                        refInput.value = result.name;
                        refInput.blur();
                      }
                      return;
                      try {
                        let allServices = [];
                        const keys = Object.keys(servicesDetails);
                        for (let i = 0; i < keys.length; i++) {
                          const subCats = servicesDetails[keys[i]];
                          const services = (subCats.map((subCat) => subCat.services)).flat();
                          allServices = [...allServices, ...services];
                        }
                        const serviceForResult = allServices
                          .find((serv) => serv.id === result.id);
                        const serviceTypeToSelect = servicesTypesList
                          .find((serviceType) => serviceType.id === serviceForResult.retail_service_type_id);
                        props.action(
                          setActiveServiceType(
                            serviceTypeToSelect,
                          ),
                        );
                        switchServiceType(serviceTypeToSelect, true);
                      } catch (e) {

                      }
                      if (refInput) {
                        refInput.value = result.name;
                        refInput.blur();
                      }
                      close();
                    }}
                  >
                    <img
                      style={{ marginRight: '1rem' }}
                      src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/Group%20106.svg"
                      alt="recent"
                    />
                    <div className={s.searchItemText}>{result.name}</div>
                  </div>
                </>
              ))}
          </div>
          {/* <div className={s.trendingContainer}>
            <h5 className={s.Trending}>Trending</h5>
            <div className={s.TrendingRow}>
              <div className={s.trendingItems}>Standard Service</div>
              <div className={s.trendingItems}>Standard Service</div>
              <div className={s.trendingItems}>Standard Service</div>
              <div className={s.trendingItems}>Standard Service</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxMobile);
